<template>
    <div>
        <!-- <UButton label="Open" @click="isOpen = true" /> -->
        <UiAppButton
            className="primary-glow-dark"
            label="Get Started"
            labelPost="for free!"
            @click="isOpen = true"
        />
        <UModal v-model="isOpen">
            <UCard
                :ui="{
                    base: 'h-full flex flex-col',
                    rounded: '',
                    background: 'bg-gray-50 dark:bg-slate-900',
                    header: {
                        background: 'bg-gray-50 dark:bg-slate-900',
                    },
                    divide: '',
                    body: {
                        base: 'grow',
                    },
                }"
            >
                <template #header>
                    <div class="flex items-center justify-between">
                        <h3
                            class="text-3xl font-semibold leading-6"
                        >
                            Find your next domain!
                        </h3>
                        <UButton
                            color="gray"
                            variant="ghost"
                            icon="i-heroicons-x-mark-20-solid"
                            class="-my-1"
                            @click="isOpen = false"
                        />
                    </div>
                </template>
                <div class="mt-10">
                    <FormKit
                        type="search"
                        placeholder="Search..."
                        label="Search any domain to Register or Transfer into Domain Pool."
                        @keydown.enter="searchDomain"
                        v-model="query"
                    />
                    <!-- When user clicks search or hits 'Enter' take them to the 'Search Results' page -->
                </div>
                <template #footer>
                    <div>
                        <UButton
                            @click="searchDomain"
                            icon="i-heroicons-arrow-right-circle"
                            trailing
                            label="Search or hit ENTER"
                            size="xl"
                            block
                        />
                    </div>
                </template>
            </UCard>
        </UModal>
    </div>
</template>

<script setup lang="ts">
const isOpen = ref(false)

import { useAuth0 } from '@auth0/auth0-vue'

// const cartStore = useCartStore()
const auth0 = process.client ? useAuth0() : undefined
const { clients } = useApollo()
const router = useRouter()

const query = ref('')
const searchDomain = () => {
    if (query.value) {
        router.push({ path: '/search-for-domains', query: { domain: query.value } })
    }
    // Track the event in Google Analytics for submitting the form
    gtag('event', 'searched_domain', {
      event_category: 'conversion',
      event_label: 'submit_domain_search_button',
    });
}
</script>

<style scoped></style>
