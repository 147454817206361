<template>
    <div>
        <NuxtLayout name="home">
            <NuxtPage />
            <HomeHero />
            <LazyHomeFeatures1 />
            <!-- <HomeFeatures2 /> -->
        </NuxtLayout>
    </div>
</template>

<script setup lang="ts">

useHead({
    link: [{ rel: 'canonical', href: 'https://domainpool.io' }],
});

// index page uses the fallback 'useSeoMeta', which can be found in 'app.vue'. All other pages sould use customized 'useSeoMeta'.

useSeoMeta({
    title: 'Better Domain Management',
    description: 'Domain management can be difficult, especially across multiple registrars. Domain Pool makes registration, transfers, management and consolidated billing easy.',
    ogType: 'website', // use 'article' for blog and guides
    ogTitle: 'Better Domain Management',
    ogDescription: 'Domain management can be difficult, especially across multiple registrars. Domain Pool makes registration, transfers, management and consolidated billing easy.',
    ogUrl: 'https://domainpool.io',
    ogImage: 'https://domainpool.io/images/pool-7.jpg',
    twitterCard: 'summary_large_image',
    twitterSite: '@domainpool_io',
    twitterTitle: 'Better Domain Management',
    twitterDescription: 'Domain management can be difficult, especially across multiple registrars. Domain Pool makes registration, transfers, management and consolidated billing easy.',
    twitterImage: 'https://domainpool.io/images/pool-7.jpg',
})

</script>
