<template>
    <button
        type="button"
        class="Button"
        :class="btnStyle"
        v-bind="$attrs"
        v-on="$listeners"
    >
        <span>{{ label }}</span>

        <span class="ml-1 text-xs italic">{{ labelPost }}</span>

        <slot></slot>
    </button>
</template>
​
<script>
export default {
    props: {
        className: {
            type: String,
            required: false,
        },
        label: {
            type: String,
            required: false,
        },
        labelPost: {
            type: String,
            required: false,
        },
    },
    computed: {
        btnStyle: function () {
            if (this.className == 'primary')
                return 'duration-300 ease-in-out inline-flex items-center rounded-md bg-primary-500 text-white dark:text-white px-3 py-2 text-sm text-white dark:text-white font-semibold shadow-sm hover:bg-primary-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-500'
            // use on light backgrounds
            else if (this.className == 'primary-glow-light')
                return 'duration-300 ease-in-out rounded-md bg-primary-600 px-3 py-2 text-sm text-white dark:text-white font-semibold hover:bg-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600 shadow-lg shadow-primary-500/50'
            // use on dark backgrounds
            else if (this.className == 'primary-glow-dark')
                return 'duration-300 ease-in-out rounded-md bg-primary-500 px-3.5 py-2.5 text-sm text-white dark:text-white font-semibold hover:bg-primary-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-400 shadow-lg shadow-primary-500/50'
            else if (this.className == 'primary-full')
                return 'duration-300 ease-in-out w-full rounded-md bg-primary-600 px-3 py-2 text-sm text-white dark:text-white font-semibold hover:bg-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600 shadow-lg shadow-primary-500/50'
            else if (this.className == 'secondary')
                return 'duration-300 ease-in-out inline-flex items-center rounded-md bg-white/10 px-3 py-2 text-sm font-semibold shadow-sm hover:bg-white/20'
            else if (this.className == 'secondary-light')
                return 'duration-300 ease-in-out lg:block lg:text-sm lg:font-semibold lg:leading-6 lg:text-primary-600 lg:hover:text-primary-500'
            else if (this.className == 'warning')
                return 'duration-300 ease-in-out rounded-md bg-red-500 px-3 py-2 text-sm font-semibold shadow-sm hover:bg-red-400'
            // WIP - use the icon in AppBanner
            else if (this.className == 'icon-close')
                return 'duration-300 ease-in-out'
            // else if (this.className == "secondary-full")
            //   return "duration-300 ease-in-out inline-flex items-center px-4 w-full justify-center py-2 border border-transparent text-sm font-medium rounded-md text-blueGray-700 bg-blueGray-100 hover:bg-blueGray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blueGray-500";
            // else if (this.className == "tertiary")
            //   return "duration-300 ease-in-out inline-flex items-center px-4 py-2 border border-blueGray-200 shadow-sm text-sm font-medium rounded-md text-blueGray-700 bg-white hover:bg-blueGray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500";
            // else if (this.className == "tertiary-full")
            //   return "duration-300 ease-in-out inline-flex items-center px-4 w-full justify-center py-2 border border-blueGray-300 shadow-sm text-sm font-medium rounded-md text-blueGray-700 bg-white hover:bg-blueGray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500";
            // else if (this.className == "warning")
            //   return "duration-300 ease-in-out w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm";
            // else if (this.className == "warning-full")
            //   return "duration-300 ease-in-out w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm";
            // else if (this.className == "icon-arrow")
            //   return "bg-slate-700 p-4 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500";
            // else if (this.className == "icon-tables")
            //   return "px-1 duration-300 ease-in-out transform-gpu hover:scale-105 text-blueGray-500 hover:text-blueGray-600";
        },
    },
}
</script>
